import { render, staticRenderFns } from "./LandLogTable.vue?vue&type=template&id=3bc4e624&scoped=true&"
import script from "./LandLogTable.vue?vue&type=script&lang=js&"
export * from "./LandLogTable.vue?vue&type=script&lang=js&"
import style0 from "./LandLogTable.vue?vue&type=style&index=0&id=3bc4e624&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bc4e624",
  null
  
)

export default component.exports