<template>
  <div>
    <ag-grid-vue
      style="width: 100%; height: 450px"
      class="ag-theme-alpine"
      :columnDefs="columnDefs"
      :rowData="rowData"
      :gridOptions="gridOptions"
      :rowSelection="rowSelection"
      suppressDragLeaveHidesColumns
      @grid-ready="onGridReady"
      checkboxSelection="true"
      suppressMovableColumns
    >
    </ag-grid-vue>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import assetImg from "../../../components/Table/components/assetImg.vue";
import btnStatus from "../../../components/Table/components/btnStatus.vue";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      columnDefs: null,
      rowSelection: "multiple",
      gridApi: null,
      gridOptions: {
        defaultColDef: {
          resizable: true,
        },
      },
    };
  },
  props: {
    dataTable: [],
    tag: { type: Boolean, default: false },
    otherColumn: [],
    page: { type: Number },
    perPage: { type: Number },
  },
  watch: {
    rowData() {
      this.setColumn();
    },
  },
  beforeMount() {
    this.setColumn();
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  },
  computed: {
    ...mapGetters({
      isRoleLower: "isRoleLower",
      permissionStatus: "permissionStatus",
    }),
    rowData() {
      return this.dataTable.map((log, index) => {
        let dataShow = {
          index: (this.page - 1) * this.perPage + index + 1,
          datetime: moment(log.createdAt).format("DD/MM/YYYY - HH:mm:ss"),
          editor: log.user,
          id_fixed_asset: {
            data: log.id_fixed_asset,
            isChange: log.edit.includes("id_fixed_asset"),
          },
          purchase_date: {
            data: moment(log.purchase_date).isValid()
              ? moment(log.purchase_date).format("DD/MM/YYYY")
              : "-",
            isChange: log.edit.includes("purchase_date"),
          },
          purchase_price: {
            data: log.purchase_price || "-",
            isChange: log.edit.includes("purchase_price"),
          },
          deed_title: {
            data: log.deed_title || "-",
            isChange: log.edit.includes("deed_title"),
          },
          location_land: {
            data: log.location_land || "-",
            isChange: log.edit.includes("location_land"),
          },
          deed_page: {
            data: log.deed_page || "-",
            isChange: log.edit.includes("deed_page"),
          },
          land_area: {
            data: log.land_area || "-",
            isChange: log.edit.includes("land_area"),
          },
          land_status: {
            data: log.land_status ? this.landStatus(log.land_status) : "-",
            isChange: log.edit.includes("land_status"),
          },
          image_url: log.image_url
            ? { data: log.image_url.url }
            : { data: require("@/image/Not-regis-qr-code.png") },
        };
        this.otherColumn.forEach((col, i) => {
          dataShow[`other_${i}`] = {
            data: log.asset_detail[i]?.value || "-",
            isChange: log.edit.includes(`${log.asset_detail[i]?.name || "-"}`),
          };
        });

        return dataShow;
      });
    },
  },
  methods: {
    onGridReady() {
      this.gridApi.sizeColumnsToFit();
    },
    momentDay(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    landStatus(status) {
      switch (status) {
        case "mortgage":
          return this.$t("depreciation.mortgage");
        case "burden free":
          return this.$t("depreciation.burden_free");
        default:
          return "-";
      }
    },
    setColumn() {
      this.columnDefs = [
        {
          headerName: this.$t("count.order"),
          field: "index",
          maxWidth: 110,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("home.fixedas_num"),
          field: "id_fixed_asset",
          sortable: true,
          suppressSizeToFit: true,
          cellRenderer: (params) => params.value.data,
          cellStyle: (params) => {
            if (params.value.isChange) {
              return { color: "#007AFE" };
            }
          },
        },
        {
          headerName: this.$t("depreciation.deed_title"),
          field: "deed_title",
          sortable: true,
          minWidth: 200,
          cellRenderer: (params) => params.value.data,
          cellStyle: (params) => {
            if (params.value.isChange) {
              return { color: "#007AFE" };
            }
          },
        },
        {
          headerName: this.$t("depreciation.location_land"),
          field: "location_land",
          sortable: true,
          minWidth: 200,
          cellRenderer: (params) => params.value.data,
          cellStyle: (params) => {
            if (params.value.isChange) {
              return { color: "#007AFE" };
            }
          },
        },
        {
          headerName: this.$t("depreciation.deed_page"),
          field: "deed_page",
          sortable: true,
          minWidth: 200,
          cellRenderer: (params) => params.value.data,
          cellStyle: (params) => {
            if (params.value.isChange) {
              return { color: "#007AFE" };
            }
          },
        },
        {
          headerName: this.$t("depreciation.land_area"),
          field: "land_area",
          sortable: true,
          minWidth: 200,
          cellRenderer: (params) => params.value.data,
          cellStyle: (params) => {
            if (params.value.isChange) {
              return { color: "#007AFE" };
            }
          },
        },
        {
          headerName: this.$t("depreciation.purchase_date"),
          field: "purchase_date",
          sortable: true,
          minWidth: 200,
          cellRenderer: (params) => params.value.data,
          cellStyle: (params) => {
            if (params.value.isChange) {
              return { color: "#007AFE" };
            }
          },
        },
        {
          headerName: this.$t("depreciation.purchase_price"),
          field: "purchase_price",
          sortable: true,
          minWidth: 200,
          cellRenderer: (params) => params.value.data,
          cellStyle: (params) => {
            if (params.value.isChange) {
              return { color: "#007AFE" };
            }
          },
        },
        {
          headerName: this.$t("depreciation.land_status"),
          field: "land_status",
          sortable: true,
          minWidth: 200,
          cellRenderer: (params) => params.value.data,
          cellStyle: (params) => {
            if (params.value.isChange) {
              return { color: "#007AFE" };
            }
          },
        },
        {
          headerName: this.$t("depreciation.deed_image"),
          field: "image_url",
          maxWidth: 130,
          suppressSizeToFit: true,
          cellRendererFramework: "assetImg",
        },
      ];
      if (this.otherColumn.length > 0) {
        let column = this.otherColumn;
        column.forEach((val, i) => {
          let header = {
            headerName: column[i].name,
            field: `other_${i}`,
            suppressSizeToFit: true,
            cellRenderer: (params) => params.value.data,
            cellStyle: (params) => {
              if (params.value.isChange) {
                return { color: "#007AFE" };
              }
            },
          };
          this.columnDefs.push(header);
        });
      }
      this.columnDefs = this.columnDefs.concat([
        {
          headerName: this.$t("depreciation.datetime_edit"),
          field: "datetime",
          width: 200,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("depreciation.editor"),
          field: "editor",
          // maxWidth: 110,
          suppressSizeToFit: true,
        },
      ]);
    },
  },
  components: {
    AgGridVue,
    btnStatus,
    assetImg,
  },
};
</script>

<style scoped>
* >>> .ag-header-cell-label {
  justify-content: center;
}
* >>> .ag-header-row-column {
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  color: white;
}
</style>
